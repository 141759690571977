
import { defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmTableActionButton,
    TmDropdown,
    TmDropdownItem,
  },
  setup() {
    const { openModal } = useModals()
    const deleteFilteredView = () => {
      openModal('confirmation', {
        modalTitle: 'Delete filtered view',
        btnText: 'Delete',
        btnColor: 'error',
        text: [
          'Are you sure you want to delete the ',
          {
            text: 'Assigned to me',
            style: 'semi-bold',
          },
          ' filtered view?\nThis action cannot be undone.',
        ],
      })
    }

    return {
      deleteFilteredView,
    }
  },
})
