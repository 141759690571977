import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bf8f873c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_chat_filtered_views_dropdown = _resolveComponent("chat-filtered-views-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, null, {
    "body-cell-name-slot": _withCtx((props) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_status, {
          class: "chat-filtered-views__status",
          color: props.row.color
        }, null, 8, ["color"]),
        _createVNode(_component_router_link, {
          class: "ml-2 font-weight-normal font-color-inherit blue-on-hover",
          to: { name: 'base.chats.settings.filteredViews.detailed' }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(props.row.name), 1)
          ]),
          _: 2
        }, 1032, ["to"])
      ])
    ]),
    "body-cell-actions": _withCtx(() => [
      _createVNode(_component_chat_filtered_views_dropdown)
    ]),
    _: 1
  }))
}