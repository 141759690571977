
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import ChatFilteredViewsFilter from '@/components/pages/chats/settings/filtered-views/ChatFilteredViewsFilter.vue'
import ChatFilteredViewsTable from '@/components/pages/chats/settings/filtered-views/ChatFilteredViewsTable.vue'
import EmptyState from '@/components/shared/templates/EmptyState.vue'
import { getTableData } from '@/services/tableService'
import type { TableHeaders } from '@/definitions/shared/types'
import { formatDate } from '@/services/dateTimeService'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    TmButton,
    ChatFilteredViewsFilter,
    DetailsHero,
    ChatsSettingsPage,
    ChatFilteredViewsTable,
    EmptyState,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'Filtered views' },
    ]
    const search = ref()
    const selected = ref()
    const tableItems = ref(getTableData('chatsFilteredViews'))
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Name', class: 'px-0', cellClass: 'px-0', value: 'name-slot', hideSortable: true },
      { text: 'Chats', hideSortable: true, value: 'chats' },
      { text: 'Last updated', value: 'lastUpdated', format: (val: string) => formatDate(val), hideSortable: true },
    ])

    return {
      isEmptyMode,
      tableItems,
      tableHeaders,
      search,
      selected,
      breadcrumbs,
    }
  },
})
